import React from "react"
import Lottie from "react-lottie"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "../components/link"
import Header from "../components/header"
import OrbitData from "../lotties/Orbit"

export default class NotFoundPage extends React.Component {

  render() {

    function defaultOptions(animationData) {
      return {
        loop: true,
        autoplay: true,
        animationData: animationData,
      }
    }   

    return <Layout hero={true}>
	    	<Seo title="Tideal – Page not found (404)" />
	    	<section style={{height: "100vh"}} className="heroSection error">
		        <Header lang="En" />
		        <div className="widthContainer">
		          <h1 className="sectionHeader">Page not found.</h1>
		          <p>We couldn't find the page you were looking for. In computer lingo, that's error code 404.</p>
		          <p>What we can do at this point is to offer our apologies and a link back to our <Link style={{color: "#FFF", fontWeight: "600"}} to="/">homepage</Link>.</p>
		          <div className="lottieWrapper">
		            <Lottie options={defaultOptions(OrbitData)} height={1350} width={1350} isClickToPauseDisabled={true} />
		          </div>
		        </div>
	      </section>
	  </Layout>
	}
}
